import { Component, OnInit } from '@angular/core';
import {FormBuilder} from '@angular/forms';

@Component({
  selector: 'app-admin-transactions',
  templateUrl: './admin-transactions.component.html',
  styleUrls: ['./admin-transactions.component.css']
})
export class AdminTransactionsComponent implements OnInit {
  queryForm = this.fb.group({
    paydunya_id : this.fb.control(''),
    viewpay_id : this.fb.control(''),
    transaction_id: this.fb.control(''),
    user_id: this.fb.control(''),
    date1 : this.fb.control(''),
    date2 : this.fb.control(''),
    status: this.fb.control(''),
    min_amount: this.fb.control(''),
    max_amount: this.fb.control(''),
    date:this.fb.control(''),
    user_phoneNumber: this.fb.control(''),
  })
  constructor(
      private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
  }

}
