import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';

import { HttpClient, HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { SignupComponent } from './signup/signup.component';
import { ProfileComponent } from './profile/profile.component';
import { HomeComponent } from './home/home.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { FooterComponent } from './shared/footer/footer.component';

import { HomeModule } from './home/home.module';
import { LoginComponent } from './login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// 1. Import the libs you need
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { TransactionsComponent } from './transactions/transactions.component';
import { SimulationComponent } from './simulation/simulation.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {MatNativeDateModule} from '@angular/material/core';
import { AdminTransactionsComponent } from './admin-transactions/admin-transactions.component';
import {DatePipe} from '@angular/common';
import {Stats2Component} from './stats2/stats2.component';
import {Stats3Component} from './stats3/stats3.component';
import {Stats4Component} from './stats4/stats4.component';
import {Stats5Component} from './stats5/stats5.component';
import {Stats6Component} from './stats6/stats6.component';

export function LanguageLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

const firebaseConfig = {
  apiKey: 'AIzaSyADpvc-r8_LxaXbW_RQzyxpA1UGx3-TLF4',
  authDomain: 'cosnaafrique.firebaseapp.com',
  databaseURL: 'https://cosnaafrique-default-rtdb.firebaseio.com',
  projectId: 'cosnaafrique',
  storageBucket: 'cosnaafrique.appspot.com',
  messagingSenderId: '875695160297',
  appId: '1:875695160297:web:b36d83b45c0219566d2978',
  measurementId: 'G-TGTZ30M6G2'
};

@NgModule({
  declarations: [
    AppComponent,
    SignupComponent,
    ProfileComponent,
    NavbarComponent,
    FooterComponent,
    LoginComponent,
    TransactionsComponent,
    SimulationComponent,
    AdminTransactionsComponent,
      Stats2Component,
      Stats3Component,
      Stats4Component,
      Stats5Component,
      Stats6Component,
  ],
    imports: [
        BrowserModule,
        NgbModule,
        FormsModule,
        RouterModule,
        AppRoutingModule,
        HomeModule,
        BrowserAnimationsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: LanguageLoader,
                deps: [HttpClient],
            },
        }),
        HttpClientModule,
        AngularFireModule.initializeApp(firebaseConfig),
        AngularFirestoreModule, // firestore
        AngularFireAuthModule, // auth
        AngularFireStorageModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatSelectModule,
        ReactiveFormsModule,
        // storage
    ],
  providers: [DatePipe],
  bootstrap: [AppComponent],
})
export class AppModule {}
