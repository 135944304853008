import {Component, OnInit} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {ApiService} from '../service/api.service';
import {ActivatedRoute} from '@angular/router';
import {DatePipe} from '@angular/common';
import {HttpClient, HttpHeaders} from '@angular/common/http';

declare const Date: any;
declare const PaiementPro: any;

@Component({
    selector: 'app-stats5',
    templateUrl: './stats5.component.html',
    styleUrls: ['./stats5.component.css']
})
export class Stats5Component implements OnInit {


    //Fedapay : 44
    // Paydunya : 3

    base_url = 'https://api.payment.cosna-afrique.com/v1/';

    current_page = 1;

    data = {
        message: '',
        country: ''
    };

    sent = [];

    countries = [];

    constructor(public afs: AngularFirestore,
                public api: ApiService,
                public activatedRoute: ActivatedRoute,
                public datePipe: DatePipe,
                private http: HttpClient) {

    }

    async ngOnInit() {
        this.activatedRoute.queryParams.subscribe(params => {
            console.log(params);
            if (params.o) {

            }
        });

        this.getData();


    }

    async getData() {
        try {
            if (localStorage.getItem('countries') !== null) {
                this.countries = JSON.parse(localStorage.getItem('countries'));
            } else {
                this.countries = (await this.api.performGetPromise(this.base_url + 'countries', null)).data;
                localStorage.setItem('countries', JSON.stringify(this.countries));
            }
            console.log(this.countries);
        } catch (e) {
            console.log(e);
            this.api.handleErrors('Une erreur s\'est produite');
        }
        //let created = await this.api.performGetPromise("https://api.payment.cosna-afrique.com/v1/users?search=created_at:"+this.date2, null);
        //let activated = await this.api.performGetPromise("https://api.payment.cosna-afrique.com/v1/users?search=created_at:"+this.date2, null);

    }


    async sendSM() {
        /*
        numberWithCode: "+330751569777"
        name: "fofana samba"
         */

        /*
        curl --location --request POST 'https://us-central1-isdg-erp.cloudfunctions.net/webApi/api/v1/send' \
          --header 'Content-Type: application/json' \
          --data-raw '{
              "to":"237696447402",
              "body":"hello Cosna Afrique test paydunya 2",
              "business_name":"Cosna"
          }'
         */
        if(this.data.country.trim().length > 0 && this.data.message.trim().length > 0 ) {
            this.sent = [];
            let users = await this.api.performGetPromise(this.base_url + 'users?search=country_id:' + this.data.country + ';canSend:1&searchJoin=and&limit=0&filter=id;numberWithCode;name;canSend;real_id&searchFields=canSend:=', null);
            console.log(users);
            users.data.forEach(async (user) => {
                console.log(user);
                try {
                    let sending = await this.api.performPostPromise('https://us-central1-isdg-erp.cloudfunctions.net/webApi/api/v1/send', {
                        'to': user.numberWithCode,
                        'body': this.data.message,
                        'business_name': 'Cosna'
                    });
                    this.sent.push({name: user.name, number: user.numberWithCode, status: 'Envoyé'});
                }
                catch (e) {
                    this.sent.push({name: user.name, number: user.numberWithCode, status: 'Echec'});
                }

            });
        }
        else {
            this.api.handleErrors("Veuillez remplir tous les champs du formulaire");
        }
    }


    //SERVICE

    performGetPromise(url) {
        let headerDict = {
            'Accept': 'application/json',
            'Content-type': 'application/json',
            //'Referer': 'https://cosna-afrique.com',
            //'Origin': 'https://cosna-afrique.com',
        };
        const reqOptions = {
            headers: new HttpHeaders(headerDict),
        };
        return new Promise<any>((resolve, reject) => {
            this.http.get(url, reqOptions).subscribe(result => {
                resolve(result);
            }, err => {
                reject(err);
            });
        });
    }

    performPATCHPromise(url, data) {
        let headerDict = {
            'Accept': 'application/json',
            'Content-type': 'application/json',
            //'Referer': 'https://cosna-afrique.com',
            //'Origin': 'https://cosna-afrique.com',
        };
        const reqOptions = {
            headers: new HttpHeaders(headerDict),
        };
        return new Promise<any>((resolve, reject) => {
            this.http.patch(url, data, reqOptions).subscribe(result => {
                resolve(result);
            }, err => {
                reject(err);
            });
        });
    }

    performPOSTPromise(url, data) {
        let headerDict = {
            'Accept': 'application/json',
            'Content-type': 'application/json',
            //'Referer': 'https://cosna-afrique.com',
            //'Origin': 'https://cosna-afrique.com',
        };
        const reqOptions = {
            headers: new HttpHeaders(headerDict),
        };
        return new Promise<any>((resolve, reject) => {
            this.http.post(url, data, reqOptions).subscribe(result => {
                resolve(result);
            }, err => {
                reject(err);
            });
        });
    }

    performFedapayPOSTPromise(url, data) {
        let headerDict = {
            'Accept': 'application/json',
            'Content-type': 'application/json',
            'Authorization': 'Bearer sk_live_eeEACzNiFIrHMNSCyjxBFAOS'
            //'Referer': 'https://cosna-afrique.com',
            //'Origin': 'https://cosna-afrique.com',
        };
        const reqOptions = {
            headers: new HttpHeaders(headerDict),
        };
        return new Promise<any>((resolve, reject) => {
            this.http.post(url, data, reqOptions).subscribe(result => {
                resolve(result);
            }, err => {
                reject(err);
            });
        });
    }

    performFedapayPUTPromise(url, data) {
        let headerDict = {
            'Accept': 'application/json',
            'Content-type': 'application/json',
            'Authorization': 'Bearer sk_live_eeEACzNiFIrHMNSCyjxBFAOS'
            //'Referer': 'https://cosna-afrique.com',
            //'Origin': 'https://cosna-afrique.com',
        };
        const reqOptions = {
            headers: new HttpHeaders(headerDict),
        };
        return new Promise<any>((resolve, reject) => {
            this.http.put(url, data, reqOptions).subscribe(result => {
                resolve(result);
            }, err => {
                reject(err);
            });
        });
    }

}
