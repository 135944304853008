import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { Routes, RouterModule } from "@angular/router";

import { HomeComponent } from "./home/home.component";
import { ProfileComponent } from "./profile/profile.component";
import { SignupComponent } from "./signup/signup.component";
import { LoginComponent } from "./login/login.component";
import {TransactionsComponent} from './transactions/transactions.component';
import {SimulationComponent} from './simulation/simulation.component';
import {AdminTransactionsComponent} from './admin-transactions/admin-transactions.component';
import {Stats2Component} from './stats2/stats2.component';
import {Stats3Component} from './stats3/stats3.component';
import {Stats4Component} from './stats4/stats4.component';
import {Stats5Component} from './stats5/stats5.component';
import {Stats6Component} from './stats6/stats6.component';

const routes: Routes = [
  { path: "home", component: HomeComponent },
  { path: "home/:id", component: HomeComponent },
  { path: "user-profile", component: ProfileComponent },
  { path: "register", component: SignupComponent },
  { path: "login", component: LoginComponent },
  { path: "transactions/:value", component: TransactionsComponent },
  { path: "admin", component: AdminTransactionsComponent },
  { path: "simulations", component: SimulationComponent },
  { path: "stats2", component: Stats2Component },
  { path: "stats3", component: Stats3Component },
  { path: "stats4", component: Stats4Component },
  { path: "stats5", component: Stats5Component },
  { path: "stats6", component: Stats6Component },
  { path: "", redirectTo: "login", pathMatch: "full" }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: true,
    }),
  ],
  exports: [],
})
export class AppRoutingModule {}
