import { Component, OnInit } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {ApiService} from '../service/api.service';
import {ActivatedRoute} from '@angular/router';
import {DatePipe} from '@angular/common';
import {HttpClient, HttpHeaders} from '@angular/common/http';

declare const Date: any;
@Component({
  selector: 'app-stats6',
  templateUrl: './stats6.component.html',
  styleUrls: ['./stats6.component.css']
})
export class Stats6Component implements OnInit {


  new_rate;
  new_rate_usd;

  data_curr_eur = 0;
  data_curr_usd = 0;


  total_pending = 0;
  total_pending_fees = 0;



  objectif = 30000000;



  charge_gains = 0;

  gains_v1 = 0;
  transfered_v1 = 0;
  fees_v1 = 0;
  pending_v1 = 0;

  gains_v2 = 0;
  transfered_v2 = 0;
  fees_v2 = 0;
  pending_v2 = 0;

  fedapay_pending = 0;
  fedapay_pending_data = [];
  fedapay_pending_fees = 0;

  transaction_pending = 0;
  transaction_pending_data = [];
  transaction_pending_fees = 0;

  flutter_operator = 38;
  flutter24_pending = 0;
  flutter24_pending_data = [];
  flutter24_pending_fees = 0;

  flutter48_pending = 0;
  flutter48_pending_data = [];
  flutter48_pending_fees = 0;


  digipay_pending = 0;
  digipay_pending_data = [];
  digipay_pending_fees = 0;

  dohone_operator = 41;
  dohone_pending = 0;
  dohone_pending_data = [];
  dohone_pending_fees = 0;

  //Fedapay : 44
  // Paydunya : 3

  base_url = 'https://api.payment.cosna-afrique.com/v1/';


  date = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
  date2 =  this.datePipe.transform(new Date(), 'yyyy-MM-dd');//"2021-12-03";
  dates = ['2021-12-04', '2021-12-05', '2021-12-06', '2021-12-07', '2021-12-08', '2021-12-09', '2021-12-10', '2021-12-11', '2021-12-12'];
  res: any = [];

  histories = [];
  data = [];

  which_op = 41;
  statut = 'PAID';

  operator_id = 0;

  /*
  EXPRESSO Partenaire 2 (24h, 8.5%)
  operator_id = 43
  provider_id = 8

  FREE MONEY Partenaire 2 (24h, 8.5%)
  operator_id = 44
  provider_id = 8

  ORANGE MONEY partenaire 2 (24h, 8.5)
  operator_id = 45
  provider_id = 8

  MTN-CI Partenaire 2 (24h, 8.5%)
  operator_id = 46
  provider_id = 8

  Moov-CI Partenaire 2 (24h, 8.5%)
  operator_id = 47
  provider_id = 8
   */

  is_sending = false;

  list_sent = [];

  constructor(public afs: AngularFirestore,
              public api: ApiService,
              public activatedRoute : ActivatedRoute,
              public datePipe: DatePipe,
              private http: HttpClient) {

    /*this.a_manager[0].amount = this.objectif;
    this.a_manager[1].amount = this.objectif;
    this.a_manager[2].amount = this.objectif;
    this.a_manager[3].amount = this.objectif;
    this.a_manager[4].amount = this.objectif;*/
  }

  async ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      console.log(params);
      if(params.operator)
        this.operator_id = params.operator;
      else
        this.operator_id = 0;

      if(params.dohone)
        this.dohone_operator = params.dohone;

      if(params.flutter)
        this.dohone_operator = params.flutter;

    });
    const new_rate = await this.api.performGetPromise('https://api.fastforex.io/convert?from=EUR&to=XOF&amount=1&api_key=d1e58e5969-fc3ca7754a-r5x7zz', null);
    this.new_rate = new_rate.result.rate;
    console.log(new_rate);
    const new_rate_usd = await this.api.performGetPromise('https://api.fastforex.io/convert?from=USD&to=XOF&amount=1&api_key=d1e58e5969-fc3ca7754a-r5x7zz', null);
    this.new_rate_usd = new_rate_usd.result.rate;
    console.log(new_rate_usd);
    Date.prototype.addDays = function (days) {
      var date = new Date(this.valueOf());
      date.setDate(date.getDate() + days);
      return date;
    };
    this.loadAll();
    //this.calcGainsv1();
    // this.getFedaPayTransactions();
    // this.getDohoneTransactions();
    //
    // this.getFedaPayTransactions();
    // this.getFlutter24Transactions(46);
    // this.getFlutter48Transactions();
  }

  loadAll(){
    this.list_sent = [];
    this.getTransactions();
    this.getTotalTransactions();
    // this.getDohoneTransactions();
    //
    // this.getFedaPayTransactions();
    // this.getFlutter24Transactions(46);
    // this.getFlutter48Transactions();
  }


  async getTransactions() {
    //this.dates.forEach(async dte => {

    let snapshots = await this.performGetPromise(this.base_url + 'transactions?search=created_at:' + this.date2 + ';status:'+this.statut+';operator_id:'+this.which_op+'&limit=0&searchJoin=and&searchFields=status:=&include=user');
    this.transaction_pending = 0;
    this.transaction_pending_data = snapshots.data;
    snapshots.data.forEach(async (d: any) => {
      this.transaction_pending += Math.round(parseFloat(d.converted_amount));
    });
    this.transaction_pending_fees = Math.round(0.003 * this.transaction_pending);
    console.log(this.transaction_pending);
    console.log(this.transaction_pending_fees);
    console.log(this.transaction_pending_data);


//        });
  }
  async getTotalTransactions() {
    //this.dates.forEach(async dte => {

    let snapshots = await this.performGetPromise(this.base_url + 'transactions?search=created_at:' + this.date2 + ';status:PAID&limit=0&searchJoin=and&searchFields=status:=&include=user');
    this.total_pending = 0;
    snapshots.data.forEach(async (d: any) => {
      this.total_pending += Math.round(parseFloat(d.converted_amount));
    });
    this.total_pending_fees = Math.round(0.003 * this.total_pending);
    console.log(this.total_pending);

//        });
  }

  async getDohoneTransactions() {
    //this.dates.forEach(async dte => {

    let snapshots = await this.performGetPromise(this.base_url + 'transactions?search=created_at:' + this.date2 + ';status:PAID;operator_id:'+this.dohone_operator+'&limit=0&searchJoin=and&searchFields=status:=&include=user');
    this.dohone_pending = 0;
    this.dohone_pending_data = snapshots.data;
    snapshots.data.forEach(async (d: any) => {
      this.dohone_pending += Math.round(parseFloat(d.converted_amount));
    });
    this.dohone_pending_fees = Math.round(0.003 * this.dohone_pending);
    console.log(this.dohone_pending);
    console.log(this.dohone_pending_fees);
    console.log(this.dohone_pending_data);


//        });
  }

  async getFedaPayTransactions() {
    //this.dates.forEach(async dte => {

    let snapshots = await this.performGetPromise(this.base_url + 'transactions?search=created_at:' + this.date2 + ';status:PAID;operator_id:44&limit=0&searchJoin=and&searchFields=status:=&include=user');
    this.fedapay_pending = 0;
    this.fedapay_pending_data = snapshots.data;
    snapshots.data.forEach(async (d: any) => {
      this.fedapay_pending += Math.round(parseFloat(d.converted_amount));
    });
    this.fedapay_pending_fees = Math.round(0.003 * this.fedapay_pending);
    console.log(this.fedapay_pending);
    console.log(this.fedapay_pending_fees);
    console.log(this.fedapay_pending_data);


//        });
  }

  async getFlutter24Transactions(operator_id=38) {
    //this.dates.forEach(async dte => {

    //new Date(new Date().setDate(new Date().getDate()-1))
    let dt = this.datePipe.transform(new Date(new Date().setDate(new Date().getDate()-0)), 'yyyy-MM-dd');
    let snapshots = await this.performGetPromise(this.base_url + 'transactions?search=created_at:' + this.date2 + ';status:PAID;operator_id:'+this.flutter_operator+'&limit=0&searchJoin=and&searchFields=status:=&include=user');
    this.flutter24_pending = 0;
    this.flutter24_pending_data = snapshots.data;
    snapshots.data.forEach(async (d: any) => {
      this.flutter24_pending += Math.round(parseFloat(d.converted_amount));
    });
    this.flutter24_pending_fees = Math.round(0.003 * this.flutter24_pending);
    console.log(this.flutter24_pending);
    console.log(this.flutter24_pending_fees);
    console.log(this.flutter24_pending_data);

  }

  async getFlutter48Transactions() {
    //this.dates.forEach(async dte => {
    let dt = this.datePipe.transform(new Date(new Date().setDate(new Date().getDate()-2)), 'yyyy-MM-dd');

    let snapshots = await this.performGetPromise(this.base_url + 'transactions?search=created_at:' + this.date2 + ';status:PAID;operator_id:39&limit=0&searchJoin=and&searchFields=status:=&include=user');
    this.flutter48_pending = 0;
    this.flutter48_pending_data = snapshots.data;
    snapshots.data.forEach(async (d: any) => {
      this.flutter48_pending += Math.round(parseFloat(d.converted_amount));
    });
    this.flutter48_pending_fees = Math.round(0.003 * this.flutter48_pending);
    console.log(this.flutter48_pending);
    console.log(this.flutter48_pending_fees);
    console.log(this.flutter48_pending_data);

  }

  async getDigipayTransactions() {
    //this.dates.forEach(async dte => {
    let dt = this.datePipe.transform(new Date(new Date().setDate(new Date().getDate()-1)), 'yyyy-MM-dd');

    let snapshots = await this.performGetPromise(this.base_url + 'transactions?search=created_at:' + dt + ';status:PAID;operator_id:39&limit=0&searchJoin=and&searchFields=status:=&include=user');
    this.digipay_pending = 0;
    this.digipay_pending_data = snapshots.data;
    snapshots.data.forEach(async (d: any) => {
      this.digipay_pending += Math.round(parseFloat(d.converted_amount));
    });
    this.digipay_pending_fees = Math.round(0.003 * this.digipay_pending);
    console.log(this.digipay_pending);
    console.log(this.digipay_pending_fees);
    console.log(this.digipay_pending_data);

  }

  getProvider(op){
    if(op == 38 || op ==41 || op == 39 || op == 53){
      return 'mtn-benin';
    }
    else if(op == 46 || op == 51 || op == 57){
      return 'mtn-ci';
    }
    else if(op == 47 || op == 52 || op == 58){
      return 'moov-ci';
    }
    else if(op == 43 || op == 48 || op == 54){
      return 'expresso-senegal';
    }
    else if(op == 44 || op == 49 || op == 55){
      return 'free-money-senegal';
    }
    else if(op == 45 || op == 50 || op == 56){
      return 'orange-money-senegal';
    }
  }

  performPostPromisePayDunya(url, params) {
    /*
    PAYDUNYA-MASTER-KEY:7cJd2Wjg-iiDs-LZHv-dxfM-iQTcoIwsoV50
PAYDUNYA-PRIVATE-KEY:live_private_x1wHDEDuAKdxyYoWGRempUN1GyO
PAYDUNYA-TOKEN:JGUJtS1xFrhfd3awMLvZ
    * */
    const headerDict = {
      'Accept-language': localStorage.getItem('lang'),
      'Content-type': 'application/json',
      'Access-Control-Allow-Origin': 'https://cosna-afrique.com',
      'PAYDUNYA-MASTER-KEY': '7cJd2Wjg-iiDs-LZHv-dxfM-iQTcoIwsoV50',
      'PAYDUNYA-PRIVATE-KEY': 'live_private_wslqnwsODTNdXLCduHNmHKbGxgZ',
      'PAYDUNYA-TOKEN': 'a0WJWQo007A4lVBIGlIo',
    };
    const reqOptions = {
      headers: new HttpHeaders(headerDict),
    };
    return new Promise<any>((resolve, reject) => {
      this.http.post(url, params, reqOptions).subscribe(result => {
        resolve(result);
      }, err => {
        reject(err);
      });
    });
  }

  async paydunyaDoDepot(partner) {
    //benificiary.data.name, benificiary.data.numberWithCode

    //if(partner == 'flutter24'){
    this.is_sending = true;
    this.api.displayMessage('Depot started please wait');
    this.list_sent = [];
      for (const transaction of this.transaction_pending_data) {

        try{
          this.list_sent.push({reference : transaction.reference_paydunya, amount : transaction.converted_amount, failed : false, status : 'En cours...'});
          console.log(transaction);
          let depot_request = await this.performPostPromisePayDunya('https://app.paydunya.com/api/v1/direct-pay/credit-account', {
            'account_alias': transaction.phoneNumber.replaceAll(' ',''),
            'amount': Math.round(transaction.converted_amount),
            'withdraw_mode': this.getProvider(transaction.operator_id),
          });
          if(depot_request.response_code == 0) {
            let snapshots = await this.performPATCHPromise(this.base_url + 'transactions/' + transaction.id, {
              status: 'SUCCESS',
              transaction_id: depot_request?.transaction_id,
              provider_ref: depot_request?.provider_ref,
            });
            console.log("send successfully : ", transaction);
            this.editTransPosition(transaction.reference_paydunya,'Sent successfully');
            //this.list_sent[] = {reference : transaction.reference_paydunya, amount : transaction.converted_amount, failed : false, status : 'Sent successfully'};
            this.getTransactions();
          }
          else {
            console.log("error", depot_request);
            this.editTransPosition(transaction.reference_paydunya,'Failed '+depot_request.response_text);
            //this.list_sent.push({reference : transaction.reference_paydunya, amount : transaction.converted_amount, failed : true, status : 'Failed '+depot_request.response_text });
          }

        } catch (e) {
          this.editTransPosition(transaction.reference_paydunya,'Request Failed '+ e.message);
          //this.list_sent.push({reference : transaction.reference_paydunya, amount : transaction.converted_amount, failed : true, status : 'Request Failed '+ e.message });
          console.log(e);
          this.api.handleErrors("une erreur s'est produit");
        }
      }
      this.is_sending = false;
    this.api.displayMessage('Tout les depots ont ete traiter');
    //}
/*
    else if(partner == 'dohone'){
      for (const transaction of this.dohone_pending_data) {

        try{
          let depot_request = await this.performPostPromisePayDunya('https://app.paydunya.com/api/v1/direct-pay/credit-account', {
            'account_alias': transaction.phoneNumber,
            'amount': Math.round(transaction.converted_amount),
            'withdraw_mode': transaction.operator_id==41 ? 'mtn-benin' : 'mtn-ci',
          });
          if(depot_request.response_code == 0) {
            let snapshots = await this.performPATCHPromise(this.base_url + 'transactions/' + transaction.id, {
              status: 'SUCCESS',
              transaction_id: depot_request?.transaction_id,
              provider_ref: depot_request?.provider_ref,
            });
            console.log("send successfully : ", transaction);
            this.getDohoneTransactions();
          }
          else
            console.log("error", depot_request);

        } catch (e) {
          console.log(e);
          this.api.handleErrors("une erreur s'est produit");
        }
      }
    }
    else if(partner == 'flutter48'){
      for (const transaction of this.flutter48_pending_data) {

        try{
          let depot_request = await this.performPostPromisePayDunya('https://app.paydunya.com/api/v1/direct-pay/credit-account', {
            'account_alias': transaction.phoneNumber,
            'amount': Math.round(transaction.converted_amount),
            'withdraw_mode': transaction.operator_id==38 ? 'mtn-benin' : 'mtn-ci',
          });
          if(depot_request.response_code == 0) {
            let snapshots = await this.performPATCHPromise(this.base_url + 'transactions/' + transaction.id, {
              status: 'SUCCESS',
              transaction_id: depot_request?.transaction_id,
              provider_ref: depot_request?.provider_ref,
            });
            console.log("send successfully : ", transaction);
            this.getFlutter48Transactions();
          }
          else
            console.log("error", depot_request);

        } catch (e) {
          console.log(e);
          this.api.handleErrors("une erreur s'est produit");
        }
      }
    }
    else if(partner == 'digipay'){
      for (const transaction of this.digipay_pending_data) {

        try{
          let depot_request = await this.performPostPromisePayDunya('https://app.paydunya.com/api/v1/direct-pay/credit-account', {
            'account_alias': transaction.phoneNumber,
            'amount': Math.round(transaction.converted_amount),
            'withdraw_mode': transaction.operator_id==38 ? 'mtn-benin' : 'mtn-ci',
          });

          if(depot_request.response_code == 0) {
            let snapshots = await this.performPATCHPromise(this.base_url + 'transactions/' + transaction.id, {
              status: 'SUCCESS',
              transaction_id: depot_request?.transaction_id,
              provider_ref: depot_request?.provider_ref,
            });
            console.log("send successfully : ", transaction);
            this.getDigipayTransactions();
          }
          else
            console.log("error", depot_request);

        } catch (e) {
          console.log(e);
          this.api.handleErrors("une erreur s'est produit");
        }
      }
    }*/


  }

  editTransPosition(ref, status){
    this.list_sent.forEach(t => {
      if(t.reference == ref)
        t.status = status;
    })
  }

  async paydunyaDoSingleDepot(partner, transaction) {
    //benificiary.data.name, benificiary.data.numberWithCode

    //if(partner == 'flutter24'){
     // for (const transaction of this.flutter24_pending_data) {
    this.api.displayMessage('Depot started please wait');
        try{
          let depot_request = await this.performPostPromisePayDunya('https://app.paydunya.com/api/v1/direct-pay/credit-account', {
            'account_alias': transaction.phoneNumber.replaceAll(' ',''),
            'amount': Math.round(transaction.converted_amount),
            'withdraw_mode': this.getProvider(transaction.operator_id),
          });
          if(depot_request.response_code == 0) {
            let snapshots = await this.performPATCHPromise(this.base_url + 'transactions/' + transaction.id, {
              status: 'SUCCESS',
              transaction_id: depot_request?.transaction_id,
              provider_ref: depot_request?.provider_ref,
            });
            console.log("send successfully : ", transaction);
            this.getTransactions();
            this.api.displayMessage('Sent successfully');
          }
          else {
            this.api.handleErrors('Depot failed ' + depot_request.response_text);
            console.log("error", depot_request);
          }

        } catch (e) {
          console.log(e);
          this.api.handleErrors("une erreur s'est produit");
        }
      //}
    //}

   /* else if(partner == 'dohone'){
      //for (const transaction of this.dohone_pending_data) {

        try{
          let depot_request = await this.performPostPromisePayDunya('https://app.paydunya.com/api/v1/direct-pay/credit-account', {
            'account_alias': transaction.phoneNumber,
            'amount': Math.round(transaction.converted_amount),
            'withdraw_mode': 'mtn-benin',
          });
          if(depot_request.response_code == 0) {
            let snapshots = await this.performPATCHPromise(this.base_url + 'transactions/' + transaction.id, {
              status: 'SUCCESS',
              transaction_id: depot_request?.transaction_id,
              provider_ref: depot_request?.provider_ref,
            });
            console.log("send successfully : ", transaction);
            this.getDohoneTransactions();
          }
          else
            console.log("error", depot_request);

        } catch (e) {
          console.log(e);
          this.api.handleErrors("une erreur s'est produit");
        }
     // }
    }
    else if(partner == 'flutter48'){
      //for (const transaction of this.flutter48_pending_data) {

        try{
          let depot_request = await this.performPostPromisePayDunya('https://app.paydunya.com/api/v1/direct-pay/credit-account', {
            'account_alias': transaction.phoneNumber,
            'amount': Math.round(transaction.converted_amount),
            'withdraw_mode': 'mtn-benin',
          });
          if(depot_request.response_code == 0) {
            let snapshots = await this.performPATCHPromise(this.base_url + 'transactions/' + transaction.id, {
              status: 'SUCCESS',
              transaction_id: depot_request?.transaction_id,
              provider_ref: depot_request?.provider_ref,
            });
            console.log("send successfully : ", transaction);
            this.getFlutter48Transactions();
          }
          else
            console.log("error", depot_request);

        } catch (e) {
          console.log(e);
          this.api.handleErrors("une erreur s'est produit");
        }
      //}
    }
    else if(partner == 'digipay'){
      //for (const transaction of this.digipay_pending_data) {

        try{
          let depot_request = await this.performPostPromisePayDunya('https://app.paydunya.com/api/v1/direct-pay/credit-account', {
            'account_alias': transaction.phoneNumber,
            'amount': Math.round(transaction.converted_amount),
            'withdraw_mode': 'mtn-benin',
          });

          if(depot_request.response_code == 0) {
            let snapshots = await this.performPATCHPromise(this.base_url + 'transactions/' + transaction.id, {
              status: 'SUCCESS',
              transaction_id: depot_request?.transaction_id,
              provider_ref: depot_request?.provider_ref,
            });
            console.log("send successfully : ", transaction);
            this.getDigipayTransactions();
          }
          else
            console.log("error", depot_request);

        } catch (e) {
          console.log(e);
          this.api.handleErrors("une erreur s'est produit");
        }
      //}
    }*/


  }


  async magmaDoDepot(partner) {
    //benificiary.data.name, benificiary.data.numberWithCode

   // if(partner == 'flutter24'){
      for (const transaction of this.flutter24_pending_data) {

        try{
          let depot_request = await this.performGetPromise('https://api.prod.payment.cosna-afrique.com/v1/magma/payout/'+transaction.id);
          console.log(depot_request);
          console.log("send successfully : ", transaction);
          this.getTransactions();

        } catch (e) {
          console.log(e);
          this.api.handleErrors("une erreur s'est produit");
        }
      }
    //}
    /*else if(partner == 'dohone'){
      for (const transaction of this.dohone_pending_data) {

        try{
          let depot_request = await this.performGetPromise('https://api.prod.payment.cosna-afrique.com/v1/magma/payout/'+transaction.id);
          console.log(depot_request);
          console.log("send successfully : ", transaction);
          this.getDohoneTransactions();

        } catch (e) {
          console.log(e);
          this.api.handleErrors("une erreur s'est produit");
        }
      }
    }
    else if(partner == 'flutter48'){
      for (const transaction of this.flutter48_pending_data) {

        try{
          let depot_request = await this.performGetPromise('https://api.prod.payment.cosna-afrique.com/v1/magma/payout/'+transaction.id);
          console.log(depot_request);
          console.log("send successfully : ", transaction);
          this.getFlutter48Transactions();

        } catch (e) {
          console.log(e);
          this.api.handleErrors("une erreur s'est produit");
        }
      }
    }
    else if(partner == 'digipay'){
      for (const transaction of this.digipay_pending_data) {

        try{
          let depot_request = await this.performGetPromise('https://api.prod.payment.cosna-afrique.com/v1/magma/payout/'+transaction.id);
          console.log(depot_request);
          console.log("send successfully : ", transaction);
          this.getDigipayTransactions();

        } catch (e) {
          console.log(e);
          this.api.handleErrors("une erreur s'est produit");
        }
      }
    }*/


  }

  async magmaDoSingleDepot(partner, transaction) {
    //benificiary.data.name, benificiary.data.numberWithCode

    //if(partner == 'flutter24'){
      //for (const transaction of this.flutter24_pending_data) {

        try{
          let depot_request = await this.performGetPromise('https://api.prod.payment.cosna-afrique.com/v1/magma/payout/'+transaction.id);
          console.log(depot_request);
          console.log("send successfully : ", transaction);
          this.getTransactions();

        } catch (e) {
          console.log(e);
          this.api.handleErrors("une erreur s'est produit");
        }
      //}
    //}
    /*else if(partner == 'dohone'){
      //for (const transaction of this.dohone_pending_data) {

        try{
          let depot_request = await this.performGetPromise('https://api.prod.payment.cosna-afrique.com/v1/magma/payout/'+transaction.id);
          console.log(depot_request);
          console.log("send successfully : ", transaction);
          this.getDohoneTransactions();

        } catch (e) {
          console.log(e);
          this.api.handleErrors("une erreur s'est produit");
        }
      //}
    }
    else if(partner == 'flutter48'){
      //for (const transaction of this.flutter48_pending_data) {

        try{
          let depot_request = await this.performGetPromise('https://api.prod.payment.cosna-afrique.com/v1/magma/payout/'+transaction.id);
          console.log(depot_request);
          console.log("send successfully : ", transaction);
          this.getFlutter48Transactions();

        } catch (e) {
          console.log(e);
          this.api.handleErrors("une erreur s'est produit");
        }
      //}
    }
    else if(partner == 'digipay'){
      //for (const transaction of this.digipay_pending_data) {

        try{
          let depot_request = await this.performGetPromise('https://api.prod.payment.cosna-afrique.com/v1/magma/payout/'+transaction.id);
          console.log(depot_request);
          console.log("send successfully : ", transaction);
          this.getDigipayTransactions();

        } catch (e) {
          console.log(e);
          this.api.handleErrors("une erreur s'est produit");
        }
      //}
    }*/


  }

  getPercentage(a1, a2){
    return Math.round((a1/a2)*100);
  }


  async fedaPayDoDepot() {
    //benificiary.data.name, benificiary.data.numberWithCode
    try {

      for (const transaction of this.fedapay_pending_data) {
        let depot_request = await this.performFedapayPOSTPromise('https://api.fedapay.com/v1/payouts', {
          'amount': transaction.converted_amount,
          'currency': {'iso': 'XOF'},
          'mode': 'mtn',
          'customer': {
            'firstname': transaction.benificiary.data.name,
            'lastname': transaction.benificiary.data.name,
            'email': transaction.user.data.email,
            'phone_number': {
              'number': transaction.benificiary.data.numberWithCode,
              'country': 'bj'
            }
          }
        });
        let payout = await this.performFedapayPUTPromise('https://api.fedapay.com/v1/payouts/start', {
          'payouts': [
            {'id': depot_request['v1/payout'].id}
          ]
        });
        let snapshots = await this.performPATCHPromise(this.base_url + 'transactions/' + transaction.id, {
          status: 'SUCCESS',
          transaction_id: depot_request['v1/payout'].id
        });
        console.log("send successfully : ", transaction);
      }

    } catch (e) {
      console.log(e);
      this.api.handleErrors("une erreur s'est produit");
    }

  }

  async calcGainsv1() {
    console.log(this.date);
    this.afs
        .collection('transactions', (req) =>
            req.where('date2', '==', this.date)
                .where('status', '==', 'SUCCESS')
        )
        .valueChanges({idField: 'id'})
        .subscribe((snapshots) => {
          this.transfered_v1 = 0;
          snapshots.forEach(async (d: any) => {
            //this.gains += parseFloat(d.converted_amount);
            this.transfered_v1 += parseFloat(d.converted_amount);
          });
          this.gains_v1 = Math.round(0.015 * this.transfered_v1);
          // console.log(this.histories);
        });

    //this.pending_v1 = 0;
    let snapshots = await this.api.getTransactions(this.date);
    snapshots.forEach(async (d: any) => {
      const paydunya_response = await this.api.performGetPromisePayDunya(`https://app.paydunya.com/api/v1/checkout-invoice/confirm/${d.reference_paydunya}`);

      //console.log(paydunya_response);
      //console.log(paydunya_response?.status);
      d.paydunya_status = paydunya_response?.status;

    });
    this.histories = snapshots;
    /*snapshots.forEach((d:any) => {
        if(d.paydunya_status == "completed" && d.status != 'SUCCESS')
            this.pending_v1 += parseInt(d.converted_amount);
    });
    this.fees_v1 = this.pending_v1 * 0.008;*/

  }


  async calcGainsv2_new() {
    console.log(this.date2);
    this.data = [];
    this.dates.forEach(async dte => {
      let dd = {
        transfered: 0,
        gains: 0,
        pending: 0,
        fees: 0,
        date: dte
      };
      let snapshots = await this.performGetPromise(this.base_url + 'transactions?search=created_at:' + dte + ';status:SUCCESS&limit=0&searchJoin=and&searchFields=status:=');
      dd.transfered = 0;
      snapshots.data.forEach(async (d: any) => {
        //this.gains += parseFloat(d.converted_amount);
        dd.transfered += Math.round(parseFloat(d.converted_amount));
      });
      dd.gains = Math.round(0.015 * dd.transfered);

      //https://api.payment.cosna-afrique.com/v1/transactions/stats/date?date=2021-12-03&pending=true
      let snapshots2 = await this.performGetPromise(this.base_url + 'transactions/stats/date?date=' + dte + '&pending=true&limit=0');
      dd.pending = 0;
      snapshots2.forEach(async (d: any) => {
        //this.gains += parseFloat(d.converted_amount);
        dd.pending += Math.round(parseFloat(d.converted_amount));
      });
      dd.fees = Math.round(0.001 * dd.pending);
      this.data.push(dd);
    });

    /*this.afs
        .collection('transactions', (req) =>
            req.where('date2', '==', this.date)
                .where('status', '==', 'SUCCESS')
        )
        .valueChanges({idField: 'id'})
        .subscribe((snapshots) => {
          this.transfered = 0;
          snapshots.forEach(async (d: any) => {
            //this.gains += parseFloat(d.converted_amount);
            this.transfered += parseFloat(d.converted_amount);
          });
          this.gains = Math.round(0.022 * this.transfered);
          // console.log(this.histories);
        });*/
  }

  async getData(): Promise<any> {
    /*return new Promise<any>(async (resolve, reject) => {
        let snapshots_eur = await this.performGetPromise(this.base_url +"transactions?search=created_at:"+this.date2+";status:SUCCESS;currency:EUR&limit=0&searchJoin=and&searchFields=status:=");
        let snapshots_usd = await this.performGetPromise(this.base_url +"transactions?search=created_at:"+this.date2+";status:SUCCESS;currency:USD&limit=0&searchJoin=and&searchFields=status:=");
        let data = {
            eur : 0,
            usd : 0,
        };
        snapshots_eur.data.forEach(async (d: any) => {
            //this.gains += parseFloat(d.converted_amount);
            data.eur += Math.round(parseFloat(d.converted_amount));
        });
        snapshots_usd.data.forEach(async (d: any) => {
            //this.gains += parseFloat(d.converted_amount);
            data.usd += Math.round(parseFloat(d.converted_amount));
        });
        resolve(data);
    });*/
  }

  async calcRateGains() {
    //let data:any = await this.api.performGetPromise("https://api.fastforex.io/convert?from=XAF&to=EUR&amount="+this.transfered_v2+"&api_key=d1e58e5969-fc3ca7754a-r5x7zz", null);
    //console.log(this.new_rate);

    //let data = await this.getData();
    //this.charge_gains = Math.round((data.eur/650) * (this.new_rate - 650)) + Math.round((data.usd/520) * (this.new_rate_usd - 520)) ;
  }

  async calcGainsv2() {
    console.log(this.date2);
    this.dates.forEach(async dte => {

      let snapshots = await this.performGetPromise(this.base_url + 'transactions?search=created_at:' + this.date2 + ';status:SUCCESS&limit=0&searchJoin=and&searchFields=status:=');
      this.transfered_v2 = 0;
      this.data_curr_eur = 0;
      this.data_curr_usd = 0;
      snapshots.data.forEach(async (d: any) => {
        //this.gains += parseFloat(d.converted_amount);
        this.transfered_v2 += Math.round(parseFloat(d.converted_amount));
        if (d.currency == 'EUR') {
          this.data_curr_eur += Math.round(parseFloat(d.converted_amount));
        }
        if (d.currency == 'USD') {
          this.data_curr_usd += Math.round(parseFloat(d.converted_amount));
        }
      });
      console.log("eur",this.data_curr_eur);
      console.log("eur_rate",(this.new_rate - 650));
      console.log("usd",this.data_curr_usd);
      console.log("usd_rate",(this.new_rate_usd - 520));
      this.charge_gains = Math.round((this.data_curr_eur / 650) * (this.new_rate - 650)) + Math.round((this.data_curr_usd / 520) * (this.new_rate_usd - 520));
      this.gains_v2 = Math.round(0.025 * this.transfered_v2);

      //https://api.payment.cosna-afrique.com/v1/transactions/stats/date?date=2021-12-03&pending=true
      let snapshots2 = await this.performGetPromise(this.base_url + 'transactions/stats/date?date=' + this.date2 + '&pending=true&limit=0');
      this.pending_v2 = 0;
      snapshots2.forEach(async (d: any) => {
        //this.gains += parseFloat(d.converted_amount);
        this.pending_v2 += Math.round(parseFloat(d.converted_amount));
      });
      this.fees_v2 = Math.round(0.005 * this.pending_v2);


    });
    //this.calcRateGains();

    /*this.afs
        .collection('transactions', (req) =>
            req.where('date2', '==', this.date)
                .where('status', '==', 'SUCCESS')
        )
        .valueChanges({idField: 'id'})
        .subscribe((snapshots) => {
          this.transfered = 0;
          snapshots.forEach(async (d: any) => {
            //this.gains += parseFloat(d.converted_amount);
            this.transfered += parseFloat(d.converted_amount);
          });
          this.gains = Math.round(0.022 * this.transfered);
          // console.log(this.histories);
        });*/
  }


  numberWithSpaces(x) {
    var parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    return parts.join('.');
  }


  //SERVICE

  performGetPromise(url) {
    let headerDict = {
      'Accept': 'application/json',
      'Content-type': 'application/json',
      //'Referer': 'https://cosna-afrique.com',
      //'Origin': 'https://cosna-afrique.com',
    };
    const reqOptions = {
      headers: new HttpHeaders(headerDict),
    };
    return new Promise<any>((resolve, reject) => {
      this.http.get(url, reqOptions).subscribe(result => {
        resolve(result);
      }, err => {
        reject(err);
      });
    });
  }

  performPATCHPromise(url, data) {
    let headerDict = {
      'Accept': 'application/json',
      'Content-type': 'application/json',
      //'Referer': 'https://cosna-afrique.com',
      //'Origin': 'https://cosna-afrique.com',
    };
    const reqOptions = {
      headers: new HttpHeaders(headerDict),
    };
    return new Promise<any>((resolve, reject) => {
      this.http.patch(url, data, reqOptions).subscribe(result => {
        resolve(result);
      }, err => {
        reject(err);
      });
    });
  }

  performPOSTPromise(url, data) {
    let headerDict = {
      'Accept': 'application/json',
      'Content-type': 'application/json',
      //'Referer': 'https://cosna-afrique.com',
      //'Origin': 'https://cosna-afrique.com',
    };
    const reqOptions = {
      headers: new HttpHeaders(headerDict),
    };
    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data, reqOptions).subscribe(result => {
        resolve(result);
      }, err => {
        reject(err);
      });
    });
  }

  performFedapayPOSTPromise(url, data) {
    let headerDict = {
      'Accept': 'application/json',
      'Content-type': 'application/json',
      'Authorization': 'Bearer sk_live_eeEACzNiFIrHMNSCyjxBFAOS'
      //'Referer': 'https://cosna-afrique.com',
      //'Origin': 'https://cosna-afrique.com',
    };
    const reqOptions = {
      headers: new HttpHeaders(headerDict),
    };
    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data, reqOptions).subscribe(result => {
        resolve(result);
      }, err => {
        reject(err);
      });
    });
  }

  performFedapayPUTPromise(url, data) {
    let headerDict = {
      'Accept': 'application/json',
      'Content-type': 'application/json',
      'Authorization': 'Bearer sk_live_eeEACzNiFIrHMNSCyjxBFAOS'
      //'Referer': 'https://cosna-afrique.com',
      //'Origin': 'https://cosna-afrique.com',
    };
    const reqOptions = {
      headers: new HttpHeaders(headerDict),
    };
    return new Promise<any>((resolve, reject) => {
      this.http.put(url, data, reqOptions).subscribe(result => {
        resolve(result);
      }, err => {
        reject(err);
      });
    });
  }

}
