import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AngularFirestore} from '@angular/fire/firestore';
import * as forge from 'node-forge';
import Swal from 'sweetalert2';

@Injectable({
    providedIn: 'root',
})
export class ApiService {

    constructor(private http: HttpClient, public afs: AngularFirestore) {
    }


    public countries = [
        /*{
            name: 'Ghana',
            code: 'gh',
            tel: '233',
            fee: 3.1,
            payWith: 'dusupay',
            feeType: 'percent',
            currency: 'GHS',
            operators: [
                {
                    code: 'airtel_gh',
                    name: 'AIRTEL',
                    id: '79',
                    depositBy: 'dusupay'
                },
                {
                    code: 'vodafone_gh',
                    name: 'VODAFONE',
                    id: '81',
                    depositBy: 'dusupay'
                },
                {
                    code: 'mtn_gh',
                    name: 'MTN MOBILE MONEY',
                    id: '82',
                    depositBy: 'dusupay'
                }
            ]
        },*/
        {
            name: 'Senegal',
            code: 'sn',
            tel: '221',
            fee: 1.5,
            payWith: 'paydunya',
            feeType: 'percent',
            currency: 'XOF',
            operators: [
                {
                    code: 'orange-money-senegal',
                    name: 'Orange Money',
                    id: '79',
                    depositBy: 'paydunya'
                },
                {
                    code: 'free-money-senegal',
                    name: 'Free Money',
                    id: '81',
                    depositBy: 'paydunya'
                },
                {
                    code: 'expresso-senegal',
                    name: 'E-Money',
                    id: '81',
                    depositBy: 'paydunya'
                }
            ]
        },
        {
            name: 'Cameroun',
            code: 'cm',
            tel: '237',
            fee: 0.5,
            payWith: 'coolpay',
            feeType: 'percent',
            currency: 'XAF',
            operators: [
                {
                    id: '392',
                    code: 'CM_OM',
                    name: 'ORANGE MONEY',
                    depositBy: 'coolpay'
                },
                {
                    id: '392',
                    code: 'CM_MOMO',
                    name: 'MTN MOBILE MONEY',
                    depositBy: 'coolpay'
                }
            ]
        },
        {
            name: 'Cote d\'Ivoire',
            code: 'ci',
            tel: '225',
            feeType: 'percent',
            fee: 1.5,
            payWith: 'paydunya',
            currency: 'XOF',
            operators: [
                /*{
                    name: '255',
                    code: 'MOBILE MONEY',
                    depositBy: 'dusupay'
                },*/
                {
                    name: 'MTN MOBILE MONEY',
                    code: 'mtn-ci',
                    depositBy: 'paydunya'
                }
            ]
        },
        /*{
            name: 'Rwanda',
            code: 'rw',
            tel: '250',
            fee: 3.1,
            payWith: 'dusupay',
            feeType: 'percent',
            currency: 'RWF',
            operators: [
                {
                    id: '230',
                    code: 'mtn_rw',
                    name: 'MTN MOBILE MONEY',
                    depositBy: 'dusupay'
                },
                {
                    id: '230',
                    code: 'airtel_rw',
                    name: 'AirtelTigo',
                    depositBy: 'dusupay'
                }
            ]
        },*/
        /* **{
            name: 'Tanzanie',
            code: 'tz',
            tel: '255',
            payWith: 'dusupay',
            fee: 400, //400 TZS (transactions below 20,000 TZS), 3% (transactions of 20,000 TZS and above)
            feeType: 'fixed',
            currency: 'TZS',
            operators: [
                {
                    name: '393',
                    code: 'MOBILE MONEY',
                    depositBy: 'dusupay'
                }
            ]
        },*/
        /*{
            name: 'Ouganda',
            code: 'UG',
            tel: '256',
            payWith: 'dusupay',
            fee: 3.1, //UGX 500 (transactions below UGX 125,000), 1% (transactions of UGX 125,000 and above)
            feeType: 'percent',
            currency: 'UGX',
            operators: [
                {
                    id: '185',
                    code: 'mtn_ug',
                    name: 'MTN MOBILE MONEY',
                    depositBy: 'dusupay'
                },
                {
                    id: '185',
                    code: 'airtel_ug',
                    name: 'Airtel',
                    depositBy: 'dusupay'
                }
            ]
        },
        {
            name: 'Zambie',
            code: 'ZM',
            tel: '260',
            fee: 3.1,
            payWith: 'dusupay',
            feeType: 'percent',
            currency: 'ZMW',
            operators: [
                {
                    id: '257',
                    code: 'mtn_zm',
                    name: 'MTN MOBILE MONEY',
                    depositBy: 'dusupay'
                }
            ]
        }*/
    ];

    optionRequete = {
        headers: new HttpHeaders({
            'Access-Control-Allow-Origin': '*',
        }),
    };

    static getUser() {
        return localStorage.getItem('cosna_user') == null
            ? null
            : JSON.parse(localStorage.getItem('cosna_user'));
    }

    performRemotePOSTCall(path, bodyParams): Observable<any> {
        return this.http.post(path, bodyParams);
    }

    performRemoteGETCall(path): Observable<any> {
        return this.http.get(path); // , this.optionRequete)
    }

    performRemoteGETTextCall(path): Observable<any> {
        return this.http.get(path, {responseType: 'text'}); // , this.optionRequete)
    }

    createCustomer(firstName, lastName, email, phone) {
        return new Promise<any>((resolve, reject) => {
            this.performRemoteGETCall(
                'http://api.cosna-afrique.com/create_customer.php?' +
                this.serialize({
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    phone: phone,
                })
            ).subscribe(
                (snapshots) => {
                    resolve(snapshots);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    }

    performGetPromise(url, queryParams) {
        return new Promise<any>((resolve, reject) => {
            let params = '';
            if (queryParams != null) {
                params = '?' + this.serialize(queryParams);
            }
            params = this.replaceAll(params, '%3A', ':');
            params = this.replaceAll(params, '%3B', ';');
            this.performRemoteGETCall(url + params).subscribe(
                (result) => {
                    resolve(result);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    }

    performGetPromise_(url, queryParams) {
        return new Promise<any>((resolve, reject) => {
            let params = '';
            if (queryParams != null) {
                params = '?' + this.serialize(queryParams);
            }
            params = this.replaceAll(params, '%3A', ':');
            params = this.replaceAll(params, '%3B', ';');
            this.performRemoteGETCall(url + params).subscribe(
                (result) => {
                    resolve(result);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    }

    performPostPromise(url, params) {
        return new Promise<any>((resolve, reject) => {
            this.performRemotePOSTCall(url, params).subscribe(
                (result) => {
                    resolve(result);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    }

    performPostPromiseToken(url, params, token) {
        const headerDict = {
            'Accept-language': localStorage.getItem('lang'),
            //Referer: 'https://cosna-afrique.com',
            'Content-type': 'application/json',
            'Access-Control-Allow-Origin': 'https://cosna-afrique.com',
            Authorization: 'Bearer ' + token, // eyJVc2VySWQiOiIxMCIsIlVzZXJBcHBSb2xlIjowLCJUb2tlbiI6Ik52YkhWMGFXOXVJREl3TVRrVDNabGNpQlRiMlowSUYiLCJFeHBpcmVEYXRlIjpudWxsfQ=='
        };
        const reqOptions = {
            headers: new HttpHeaders(headerDict),
        };
        return new Promise<any>((resolve, reject) => {
            this.http.post(url, params, reqOptions).subscribe(
                (result) => {
                    resolve(result);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    }

    performPostPromiseTokenContentType(url, params, token, contenType) {
        const headerDict = {
            Accept: 'application/json',
            'Accept-language': 'en_US',
            'Content-type': contenType,
            Authorization: 'Basic ' + token, // eyJVc2VySWQiOiIxMCIsIlVzZXJBcHBSb2xlIjowLCJUb2tlbiI6Ik52YkhWMGFXOXVJREl3TVRrVDNabGNpQlRiMlowSUYiLCJFeHBpcmVEYXRlIjpudWxsfQ=='
        };
        const reqOptions = {
            headers: new HttpHeaders(headerDict),
        };
        return new Promise<any>((resolve, reject) => {
            this.http.post(url, params, reqOptions).subscribe(
                (result) => {
                    resolve(result);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    }

    performPostPromisePayDunya(url, params) {
        /*
        PAYDUNYA-MASTER-KEY:7cJd2Wjg-iiDs-LZHv-dxfM-iQTcoIwsoV50
PAYDUNYA-PRIVATE-KEY:live_private_x1wHDEDuAKdxyYoWGRempUN1GyO
PAYDUNYA-TOKEN:JGUJtS1xFrhfd3awMLvZ
        * */
        const headerDict = {
            'Accept-language': localStorage.getItem('lang'),
            'Content-type': 'application/json',
            'Access-Control-Allow-Origin': 'https://cosna-afrique.com',
            'PAYDUNYA-MASTER-KEY': '7cJd2Wjg-iiDs-LZHv-dxfM-iQTcoIwsoV50',
            'PAYDUNYA-PRIVATE-KEY': 'live_private_wslqnwsODTNdXLCduHNmHKbGxgZ',
            'PAYDUNYA-TOKEN': 'a0WJWQo007A4lVBIGlIo',
        };
        const reqOptions = {
            headers: new HttpHeaders(headerDict),
        };
        return new Promise<any>((resolve, reject) => {
            this.http.post(url, params, reqOptions).subscribe(result => {
                resolve(result);
            }, err => {
                reject(err);
            });
        });
    }

    performPostPromiseDiool(url, params) {
        /*
        PAYDUNYA-MASTER-KEY:7cJd2Wjg-iiDs-LZHv-dxfM-iQTcoIwsoV50
PAYDUNYA-PRIVATE-KEY:live_private_x1wHDEDuAKdxyYoWGRempUN1GyO
PAYDUNYA-TOKEN:JGUJtS1xFrhfd3awMLvZ
        * */
        const headerDict = {
            'Accept-language': localStorage.getItem('lang'),
            'Content-type': 'application/json',
            'Access-Control-Allow-Origin': 'https://cosna-afrique.com',
            'Authorization': 'Bearer api_live.HwjJrAhua0m8VvHDIVonQf9CJY+rJA82hYmpQuk5u6V16Um12GZ+z+CnKWoVqXpF',
        };
        const reqOptions = {
            headers: new HttpHeaders(headerDict),
        };
        return new Promise<any>((resolve, reject) => {
            this.http.post(url, params, reqOptions).subscribe(result => {
                resolve(result);
            }, err => {
                reject(err);
            });
        });
    }

    performPostPromiseDusupay(url, params) {
        const headerDict = {
            'Accept-language': localStorage.getItem('lang'),
            //'Referer': 'https://cosna-afrique.com',
            'Content-type': 'application/json',
            'Access-Control-Allow-Origin': 'https://cosna-afrique.com',
            'secret-key': 'SECK-2021ad770d31ed964942187cea0a7f358',
        };
        const reqOptions = {
            headers: new HttpHeaders(headerDict),
        };
        return new Promise<any>((resolve, reject) => {
            this.http.post(url, params, reqOptions).subscribe(result => {
                resolve(result);
            }, err => {
                reject(err);
            });
        });
    }

    generator(): string {
        const isString = `${this.S4()}${this.S4()}-${this.S4()}-${this.S4()}-${this.S4()}-${this.S4()}${this.S4()}${this.S4()}`;

        return isString;
    }

    private S4(): string {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    }

    makeid(length) {
        let result = '';
        const characters =
            'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    getUserById(id) {
        return new Promise<any>((resolve, reject) => {
            this.afs
                .collection('users')
                .doc(id)
                .valueChanges()
                .subscribe(
                    (snapshots) => {
                        resolve(snapshots);
                    },
                    (err) => {
                        reject(err);
                    }
                );
        });
    }
    getTransactions(date) {
        return new Promise<any>((resolve, reject) => {
            this.afs
                .collection('transactions', (req) =>
                    req.where('date2', '==', date)
                    //.where('user_id', '==', 'RdmgvgM03MO4BnSHgYVh7YRwFIw2')
                        .orderBy('timestamp', 'desc'))
                .valueChanges({idField: 'id'})
                .subscribe(
                    (snapshots) => {
                        resolve(snapshots);
                    },
                    (err) => {
                        reject(err);
                    }
                );
        });
    }
    performGetPromisePayDunya(url) {
        let headerDict = {
            'Accept-language': localStorage.getItem('lang'),
            //'Referer': 'https://cosna-afrique.com',
            'Content-type': 'application/json',
            'Access-Control-Allow-Origin': 'https://cosna-afrique.com',
            'PAYDUNYA-MASTER-KEY': '7cJd2Wjg-iiDs-LZHv-dxfM-iQTcoIwsoV50',
            'PAYDUNYA-PRIVATE-KEY': 'live_private_wslqnwsODTNdXLCduHNmHKbGxgZ',
            'PAYDUNYA-TOKEN': 'a0WJWQo007A4lVBIGlIo',
        };
        const reqOptions = {
            headers: new HttpHeaders(headerDict),
        };
        return new Promise<any>((resolve, reject) => {
            this.http.get(url, reqOptions).subscribe(result => {
                resolve(result);
            }, err => {
                reject(err);
            });
        });
    }

    performGetTextPromise(url, queryParams) {
        return new Promise<any>((resolve, reject) => {
            let params = '';
            if (queryParams != null) {
                params = '?' + this.serialize(queryParams);
            }
            params = this.replaceAll(params, '%3A', ':');
            params = this.replaceAll(params, '%3B', ';');
            this.performRemoteGETTextCall(url + params).subscribe(
                (result) => {
                    resolve(result);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    }

    replaceAll(str, find, replace) {
        return str.replace(new RegExp(find, 'g'), replace);
    }

    serialize(obj) {
        const str = [];
        for (const p in obj) {
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
            }
        }
        return str.join('&');
    }

    encrypt(key, text) {
        // var forge =  ;
        const cipher = forge.cipher.createCipher(
            '3DES-ECB',
            forge.util.createBuffer(key)
        );
        cipher.start({iv: ''});
        cipher.update(forge.util.createBuffer(text, 'utf-8'));
        cipher.finish();
        const encrypted = cipher.output;
        return forge.util.encode64(encrypted.getBytes());
    }

    async handleErrors(res) {
        const toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: false,
            icon: 'error',
            customClass: {
                popup: 'bg-danger w-100 p-3',
                title: 'text-white',
            },
        });
        toast.fire({
            title: res,
        });
    }

    async displayMessage(res) {
        const toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            timer: 3000,
            timerProgressBar: true,
            icon: 'success',
            customClass: {
                popup: 'bg-success w-100 p-3',
                title: 'text-white',
            },
        });
        toast.fire({
            title: res,
        });
    }

    isOrangeNumber(number) {
        const reg = new RegExp(/(6)((5[5-9])|9[0-9])[0-9]{6}/g);
        return reg.test(number);
    }

    isMtnNumber(number) {
        const reg = new RegExp(/(6)((5[0-4])|8[0-9]|7[0-9])[0-9]{6}/g);
        return reg.test(number);
    }

    isOrangeNumberCI(number) {
        const reg = new RegExp(/(07|08|09|4[7-9]|5[7-9]|7[7-8]|8[7-9])[0-9]{8}/g);
        //// console.log("CI OM", reg.test(String(number)));
        return reg.test(String(number));
    }

    isMtnNumberCI(number) {
        const reg = new RegExp(/(04|05|06|4[4-6]|5[5-6]|8[4-6])[0-9]{8}/g);
        //// console.log("CI MTN", reg.test(String(number)));
        return reg.test(String(number));
    }

    isValidEmail(email) {
        const regexp = new RegExp(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
        return regexp.test(email);
    }

    getCountry(name) {
        const country = this.countries.find(c => c.code === name);
        return country;
    }

    getOperator(code, country) {
        let op = null;
        country.operators.forEach(c => {
            if (c.code === code) {
                op = c;
            }
        });
        return op;
    }



    getDates(startDate, stopDate) {
        var dateArray = new Array();
        var currentDate = startDate;
        while (currentDate <= stopDate) {
            dateArray.push(new Date (currentDate));
            currentDate = currentDate.addDays(1);
        }
        return dateArray;
    }
}
