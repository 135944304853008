import { Component, OnInit } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {ApiService} from '../service/api.service';
import {DatePipe} from '@angular/common';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';


declare const Date: any;

@Component({
  selector: 'app-stats4',
  templateUrl: './stats4.component.html',
  styleUrls: ['./stats4.component.css']
})

export class Stats4Component implements OnInit {


  new_rate;
  new_rate_usd;

  data_curr_eur = 0;
  data_curr_usd = 0;


  a_manager = [
    {
      name : 'Yeckson',
      api_name : 'yeckson',
      amount : 0,
      sent : 0,
    },
    {
      name : 'Raissa',
      api_name : 'raissa',
      amount : 0,
      sent : 0,
    },
    {
      name : 'Mani',
      api_name : 'mani',
      amount : 0,
      sent : 0,
    },
    {
      name : 'Vanessa',
      api_name : 'vanessa',
      amount : 0,
      sent : 0,
    },
    /*{
      name : 'Aminata',
      api_name : 'aminata',
      amount : 0,
      sent : 0,
    },*/
    {
      name : 'Mimi',
      api_name : 'mimi',
      amount : 0,
      sent : 0,
    },
    {
      name : 'Kevine',
      api_name : 'kevine',
      amount : 0,
      sent : 0,
    },
    {
      name : 'Therese',
      api_name : 'therese',
      amount : 0,
      sent : 0,
    }
  ];


  objectif = 30000000;



  charge_gains = 0;

  gains_v1 = 0;
  transfered_v1 = 0;
  fees_v1 = 0;
  pending_v1 = 0;

  gains_v2 = 0;
  transfered_v2 = 0;
  fees_v2 = 0;
  pending_v2 = 0;

  fedapay_pending = 0;
  fedapay_pending_data = [];
  fedapay_pending_fees = 0;

  //Fedapay : 44
  // Paydunya : 3

  base_url = 'https://api.payment.cosna-afrique.com/v1/';


  date = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
  date2 =  this.datePipe.transform(new Date(), 'yyyy-MM-dd');//"2021-12-03";
  date3 =  this.datePipe.transform(new Date(), 'yyyy-MM-dd');//"2021-12-03";
  dates = ['2021-12-04', '2021-12-05', '2021-12-06', '2021-12-07', '2021-12-08', '2021-12-09', '2021-12-10', '2021-12-11', '2021-12-12'];
  res: any = [];

  histories = [];
  data = [];

  constructor(public afs: AngularFirestore,
              public api: ApiService,
              public activatedRoute : ActivatedRoute,
              public datePipe: DatePipe,
              private http: HttpClient) {

    /*this.a_manager[0].amount = this.objectif;
    this.a_manager[1].amount = this.objectif;
    this.a_manager[2].amount = this.objectif;
    this.a_manager[3].amount = this.objectif;
    this.a_manager[4].amount = this.objectif;*/
  }

  async ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      console.log(params);
      if(params.o){
        this.a_manager.forEach(m => {
          m.amount = parseInt(params.o);
        })
      }
    });
    this.calcGainsv2();
    //setInterval(() => {
      /*this.pending_v1 = 0;
      var nodesSnapshot :any = document.evaluate("//tr[@style='background: red; display: table-row;']/td[@class='amount']", document, null, XPathResult.ORDERED_NODE_SNAPSHOT_TYPE, null );
      for ( var i=0 ; i < nodesSnapshot.snapshotLength; i++ ){
          this.pending_v1 += parseInt( nodesSnapshot.snapshotItem(i).innerText );

      }

      this.fees_v1 = this.pending_v1 * 0.008;
      console.log(this.pending_v1);*/
      //this.calcGainsv2();
      /*this.getTrafic("yeckson");
      this.getTrafic("raissa");
      this.getTrafic("vanessa");
      this.getTrafic("aminata");
      this.getTrafic("mani");*/
    //}, 30000);
  }

  getTotal(){
    let total = 0;
    this.a_manager.forEach(m => {
      total += m.amount;
    });
    return total;
  }

  getTotal2(){
    let total = 0;
    this.a_manager.forEach(m => {
      total += m.sent;
    });
    return total;
  }

  async getTrafic(name){
    let data = await this.api.performGetPromise("https://api.prod.payment.cosna-afrique.com/v1/daily/stats?date="+this.date2+"&name="+name+"&to="+this.date3, null);
    console.log(data);
    this.a_manager.forEach(m => {
      if(m.api_name == name)
        m.sent = data.total_amount;
    });
    //return parseFloat(data.total_amount ? data.total_amount : 0);
    //console.log("called", name);
    //return 0;
  }



  async getFedaPayTransactions() {
    //this.dates.forEach(async dte => {

    let snapshots = await this.performGetPromise(this.base_url + 'transactions?search=created_at:' + this.date2 + ';operator_id:44;status:PAID&limit=0&searchJoin=and&searchFields=status:=&include=user');
    this.fedapay_pending = 0;
    this.fedapay_pending_data = snapshots.data;
    snapshots.data.forEach(async (d: any) => {
      this.fedapay_pending += Math.round(parseFloat(d.converted_amount));
    });
    this.fedapay_pending_fees = Math.round(0.003 * this.fedapay_pending);
    console.log(this.fedapay_pending);
    console.log(this.fedapay_pending_fees);
    console.log(this.fedapay_pending_data);


//        });
  }

  getPercentage(a1, a2){
    return Math.round((a1/a2)*100);
  }


  async fedaPayDoDepot() {
    //benificiary.data.name, benificiary.data.numberWithCode
    try {

      for (const transaction of this.fedapay_pending_data) {
        let depot_request = await this.performFedapayPOSTPromise('https://api.fedapay.com/v1/payouts', {
          'amount': transaction.converted_amount,
          'currency': {'iso': 'XOF'},
          'mode': 'mtn',
          'customer': {
            'firstname': transaction.benificiary.data.name,
            'lastname': transaction.benificiary.data.name,
            'email': transaction.user.data.email,
            'phone_number': {
              'number': transaction.benificiary.data.numberWithCode,
              'country': 'bj'
            }
          }
        });
        let payout = await this.performFedapayPUTPromise('https://api.fedapay.com/v1/payouts/start', {
          'payouts': [
            {'id': depot_request['v1/payout'].id}
          ]
        });
        let snapshots = await this.performPATCHPromise(this.base_url + 'transactions/' + transaction.id, {
          status: 'SUCCESS',
          transaction_id: depot_request['v1/payout'].id
        });
        console.log("send successfully : ", transaction);
      }

    } catch (e) {
      console.log(e);
      this.api.handleErrors("une erreur s'est produit");
    }

  }

  async calcGainsv1() {
    console.log(this.date);
    this.afs
        .collection('transactions', (req) =>
            req.where('date2', '==', this.date)
                .where('status', '==', 'SUCCESS')
        )
        .valueChanges({idField: 'id'})
        .subscribe((snapshots) => {
          this.transfered_v1 = 0;
          snapshots.forEach(async (d: any) => {
            //this.gains += parseFloat(d.converted_amount);
            this.transfered_v1 += parseFloat(d.converted_amount);
          });
          this.gains_v1 = Math.round(0.015 * this.transfered_v1);
          // console.log(this.histories);
        });

    //this.pending_v1 = 0;
    let snapshots = await this.api.getTransactions(this.date);
    snapshots.forEach(async (d: any) => {
      const paydunya_response = await this.api.performGetPromisePayDunya(`https://app.paydunya.com/api/v1/checkout-invoice/confirm/${d.reference_paydunya}`);

      //console.log(paydunya_response);
      //console.log(paydunya_response?.status);
      d.paydunya_status = paydunya_response?.status;

    });
    this.histories = snapshots;
    /*snapshots.forEach((d:any) => {
        if(d.paydunya_status == "completed" && d.status != 'SUCCESS')
            this.pending_v1 += parseInt(d.converted_amount);
    });
    this.fees_v1 = this.pending_v1 * 0.008;*/

  }


  async calcGainsv2_new() {
    console.log(this.date2);
    this.data = [];
    this.dates.forEach(async dte => {
      let dd = {
        transfered: 0,
        gains: 0,
        pending: 0,
        fees: 0,
        date: dte
      };
      let snapshots = await this.performGetPromise(this.base_url + 'transactions?search=created_at:' + dte + ';status:SUCCESS&limit=0&searchJoin=and&searchFields=status:=');
      dd.transfered = 0;
      snapshots.data.forEach(async (d: any) => {
        //this.gains += parseFloat(d.converted_amount);
        dd.transfered += Math.round(parseFloat(d.converted_amount));
      });
      dd.gains = Math.round(0.015 * dd.transfered);

      //https://api.payment.cosna-afrique.com/v1/transactions/stats/date?date=2021-12-03&pending=true
      let snapshots2 = await this.performGetPromise(this.base_url + 'transactions/stats/date?date=' + dte + '&pending=true&limit=0');
      dd.pending = 0;
      snapshots2.forEach(async (d: any) => {
        //this.gains += parseFloat(d.converted_amount);
        dd.pending += Math.round(parseFloat(d.converted_amount));
      });
      dd.fees = Math.round(0.001 * dd.pending);
      this.data.push(dd);
    });

    /*this.afs
        .collection('transactions', (req) =>
            req.where('date2', '==', this.date)
                .where('status', '==', 'SUCCESS')
        )
        .valueChanges({idField: 'id'})
        .subscribe((snapshots) => {
          this.transfered = 0;
          snapshots.forEach(async (d: any) => {
            //this.gains += parseFloat(d.converted_amount);
            this.transfered += parseFloat(d.converted_amount);
          });
          this.gains = Math.round(0.022 * this.transfered);
          // console.log(this.histories);
        });*/
  }

  async getData(): Promise<any> {
    /*return new Promise<any>(async (resolve, reject) => {
        let snapshots_eur = await this.performGetPromise(this.base_url +"transactions?search=created_at:"+this.date2+";status:SUCCESS;currency:EUR&limit=0&searchJoin=and&searchFields=status:=");
        let snapshots_usd = await this.performGetPromise(this.base_url +"transactions?search=created_at:"+this.date2+";status:SUCCESS;currency:USD&limit=0&searchJoin=and&searchFields=status:=");
        let data = {
            eur : 0,
            usd : 0,
        };
        snapshots_eur.data.forEach(async (d: any) => {
            //this.gains += parseFloat(d.converted_amount);
            data.eur += Math.round(parseFloat(d.converted_amount));
        });
        snapshots_usd.data.forEach(async (d: any) => {
            //this.gains += parseFloat(d.converted_amount);
            data.usd += Math.round(parseFloat(d.converted_amount));
        });
        resolve(data);
    });*/
  }

  async calcRateGains() {
    //let data:any = await this.api.performGetPromise("https://api.fastforex.io/convert?from=XAF&to=EUR&amount="+this.transfered_v2+"&api_key=d1e58e5969-fc3ca7754a-r5x7zz", null);
    //console.log(this.new_rate);

    //let data = await this.getData();
    //this.charge_gains = Math.round((data.eur/650) * (this.new_rate - 650)) + Math.round((data.usd/520) * (this.new_rate_usd - 520)) ;
  }

  async calcGainsv2() {
    console.log(this.date2);
    //this.dates.forEach(async dte => {

      let snapshots = await this.performGetPromise(this.base_url + 'amount/transfered?date=' + this.date2+"&to="+this.date3);
      this.transfered_v2 = snapshots[0].total_amount;
      this.data_curr_eur = snapshots[0].eur;
      this.data_curr_usd = snapshots[0].usd;
      /*snapshots.data.forEach(async (d: any) => {
        //this.gains += parseFloat(d.converted_amount);
        this.transfered_v2 += Math.round(parseFloat(d.converted_amount));
        if (d.currency == 'EUR') {
          this.data_curr_eur += Math.round(parseFloat(d.converted_amount));
        }
        if (d.currency == 'USD') {
          this.data_curr_usd += Math.round(parseFloat(d.converted_amount));
        }
      });*/
      console.log("eur",this.data_curr_eur);
      console.log("eur_rate",(this.new_rate - 650));
      console.log("usd",this.data_curr_usd);
      console.log("usd_rate",(this.new_rate_usd - 520));
      this.charge_gains = Math.round((this.data_curr_eur / 650) * (this.new_rate - 650)) + Math.round((this.data_curr_usd / 520) * (this.new_rate_usd - 520));
      this.gains_v2 = Math.round(0.025 * this.transfered_v2);

      //https://api.payment.cosna-afrique.com/v1/transactions/stats/date?date=2021-12-03&pending=true
      //let snapshots2 = await this.performGetPromise(this.base_url + 'transactions/stats/date?date=' + this.date2 + '&pending=true&limit=0');
      //this.pending_v2 = 0;
      /*snapshots2.forEach(async (d: any) => {
        //this.gains += parseFloat(d.converted_amount);
        this.pending_v2 += Math.round(parseFloat(d.converted_amount));
      });
      this.fees_v2 = Math.round(0.005 * this.pending_v2);*/


    //});

      /*this.getTrafic("yeckson");
      this.getTrafic("raissa");
      this.getTrafic("vanessa");
      this.getTrafic("aminata");
      this.getTrafic("mani");
      this.getTrafic("mani");
      this.getTrafic("mani");
      this.getTrafic("mani");*/

      this.a_manager.forEach(m => {
        this.getTrafic(m.api_name);
      })
    //this.calcRateGains();

    /*this.afs
        .collection('transactions', (req) =>
            req.where('date2', '==', this.date)
                .where('status', '==', 'SUCCESS')
        )
        .valueChanges({idField: 'id'})
        .subscribe((snapshots) => {
          this.transfered = 0;
          snapshots.forEach(async (d: any) => {
            //this.gains += parseFloat(d.converted_amount);
            this.transfered += parseFloat(d.converted_amount);
          });
          this.gains = Math.round(0.022 * this.transfered);
          // console.log(this.histories);
        });*/
  }


  numberWithSpaces(x) {
    var parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    return parts.join('.');
  }


  //SERVICE

  performGetPromise(url) {
    let headerDict = {
      'Accept': 'application/json',
      'Content-type': 'application/json',
      //'Referer': 'https://cosna-afrique.com',
      //'Origin': 'https://cosna-afrique.com',
    };
    const reqOptions = {
      headers: new HttpHeaders(headerDict),
    };
    return new Promise<any>((resolve, reject) => {
      this.http.get(url, reqOptions).subscribe(result => {
        resolve(result);
      }, err => {
        reject(err);
      });
    });
  }

  performPATCHPromise(url, data) {
    let headerDict = {
      'Accept': 'application/json',
      'Content-type': 'application/json',
      //'Referer': 'https://cosna-afrique.com',
      //'Origin': 'https://cosna-afrique.com',
    };
    const reqOptions = {
      headers: new HttpHeaders(headerDict),
    };
    return new Promise<any>((resolve, reject) => {
      this.http.patch(url, data, reqOptions).subscribe(result => {
        resolve(result);
      }, err => {
        reject(err);
      });
    });
  }

  performPOSTPromise(url, data) {
    let headerDict = {
      'Accept': 'application/json',
      'Content-type': 'application/json',
      //'Referer': 'https://cosna-afrique.com',
      //'Origin': 'https://cosna-afrique.com',
    };
    const reqOptions = {
      headers: new HttpHeaders(headerDict),
    };
    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data, reqOptions).subscribe(result => {
        resolve(result);
      }, err => {
        reject(err);
      });
    });
  }

  performFedapayPOSTPromise(url, data) {
    let headerDict = {
      'Accept': 'application/json',
      'Content-type': 'application/json',
      'Authorization': 'Bearer sk_live_eeEACzNiFIrHMNSCyjxBFAOS'
      //'Referer': 'https://cosna-afrique.com',
      //'Origin': 'https://cosna-afrique.com',
    };
    const reqOptions = {
      headers: new HttpHeaders(headerDict),
    };
    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data, reqOptions).subscribe(result => {
        resolve(result);
      }, err => {
        reject(err);
      });
    });
  }

  performFedapayPUTPromise(url, data) {
    let headerDict = {
      'Accept': 'application/json',
      'Content-type': 'application/json',
      'Authorization': 'Bearer sk_live_eeEACzNiFIrHMNSCyjxBFAOS'
      //'Referer': 'https://cosna-afrique.com',
      //'Origin': 'https://cosna-afrique.com',
    };
    const reqOptions = {
      headers: new HttpHeaders(headerDict),
    };
    return new Promise<any>((resolve, reject) => {
      this.http.put(url, data, reqOptions).subscribe(result => {
        resolve(result);
      }, err => {
        reject(err);
      });
    });
  }

}
